import React, { useEffect, useCallback, useState } from 'react';
import Movie from './Movie';
import classes from './MoviesList.module.css';

const MovieList = (props) => {
  
  return (
    <section>
      <ul className={classes['movies-list']} key="1">
        {
          props.movies.map((movie) => {
            return <Movie
              title={movie.title}
              conversionstatus={movie.conversionstatus}
              converteduri={movie.converteduri}
              totalvotes={movie.totalvotes}
              user={props.user}
              id={movie.id}
              key={movie.id}
              votes={props.votes}
            />
          })
        }
      </ul>
    </section>
  );
};

export default MovieList;
