import React from 'react';
import VideoPlayer from './VideoPlayer';

import classes from './Movie.module.css';
import Votes from './Votes';

const Movie = (props) => {
  return (
    <li className={classes.movie} key={`mov`+props.id} id={props.id}>
      {/* <h2>{props.title}</h2> */}
      <VideoPlayer url={props.converteduri} user={props.user}/>
      <Votes key={`votes`+props.id} id={props.id} totalvotes={props.totalvotes} user={props.user} votes={props.votes}></Votes>
    </li>
  );
};

export default Movie;
