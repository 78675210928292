import React from 'react';
import LikeButton from './LikeButton';

const Votes = (props) => {
    return (
        <React.Fragment>
            <LikeButton key={`like`+props.id} id={props.id} user={props.user} totalvotes={props.totalvotes} votes={props.votes}/>
        </React.Fragment>
    );
}

export default Votes;