/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMovie = /* GraphQL */ `
  mutation CreateMovie(
    $input: CreateMovieInput!
    $condition: ModelMovieConditionInput
  ) {
    createMovie(input: $input, condition: $condition) {
      id
      title
      openingtext
      video
      conversionstatus
      converteduri
      totalvotes
      votes {
        items {
          id
          user
          createdAt
          updatedAt
          movieVotesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMovie = /* GraphQL */ `
  mutation UpdateMovie(
    $input: UpdateMovieInput!
    $condition: ModelMovieConditionInput
  ) {
    updateMovie(input: $input, condition: $condition) {
      id
      title
      openingtext
      video
      conversionstatus
      converteduri
      totalvotes
      votes {
        items {
          id
          user
          createdAt
          updatedAt
          movieVotesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMovie = /* GraphQL */ `
  mutation DeleteMovie(
    $input: DeleteMovieInput!
    $condition: ModelMovieConditionInput
  ) {
    deleteMovie(input: $input, condition: $condition) {
      id
      title
      openingtext
      video
      conversionstatus
      converteduri
      totalvotes
      votes {
        items {
          id
          user
          createdAt
          updatedAt
          movieVotesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVotes = /* GraphQL */ `
  mutation CreateVotes(
    $input: CreateVotesInput!
    $condition: ModelVotesConditionInput
  ) {
    createVotes(input: $input, condition: $condition) {
      id
      user
      createdAt
      updatedAt
      movieVotesId
    }
  }
`;
export const updateVotes = /* GraphQL */ `
  mutation UpdateVotes(
    $input: UpdateVotesInput!
    $condition: ModelVotesConditionInput
  ) {
    updateVotes(input: $input, condition: $condition) {
      id
      user
      createdAt
      updatedAt
      movieVotesId
    }
  }
`;
export const deleteVotes = /* GraphQL */ `
  mutation DeleteVotes(
    $input: DeleteVotesInput!
    $condition: ModelVotesConditionInput
  ) {
    deleteVotes(input: $input, condition: $condition) {
      id
      user
      createdAt
      updatedAt
      movieVotesId
    }
  }
`;
