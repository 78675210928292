import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import { ReactComponent as Hand } from "./hand.svg";
import { API } from "aws-amplify";
import {
    updateMovie as updateMovieMutation,
    createVotes as createVotesMutation,
    deleteVotes as deleteVotesMutation
} from "./../graphql/mutations";

import {
    getMovie
} from "./../graphql/queries";

import "./styles.scss";

const particleList = Array.from(Array(10));

const LikeButton = (props) => {
    const [liked, setLiked] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [voteAction, setVoteAction] = useState(null);
    const [voteID, setvoteID] = useState(null);
    const [votesCount, setVotesCount] = useState(null);
    const [actionInProgress, setActionInProgress] = useState(false);

    useEffect(() => {
        const filtered = props.votes.filter((vote) => {
            return vote.movieVotesId === props.id;
        });
        if (filtered.length > 0){
            setLiked(true);
            setvoteID(filtered[0].id);
        }
        setVotesCount(props.totalvotes);
    }, [props]);

    useEffect(useCallback(() => {
        async function updateVotes() {
            const apiData = await API.graphql({ query: getMovie, variables: { id: props.id } });
            const selectedMovie = apiData.data.getMovie;
            if (voteAction === 'ADD') {
                //Get the current number of votes
                const movie = {
                    id: props.id,
                    totalvotes: selectedMovie.totalvotes + 1
                };
                setVotesCount(selectedMovie.totalvotes + 1);
                const votes = {
                    user: props.user.username,
                    movieVotesId: props.id
                }
                await API.graphql({
                    query: updateMovieMutation,
                    variables: { input: movie },
                });
                const addedVote = await API.graphql({
                    query: createVotesMutation,
                    variables: { input: votes },
                });
                setvoteID(addedVote.data.createVotes.id);
            }
            else if (voteAction === 'REDUCE') {
                const movie = {
                    id: props.id,
                    totalvotes: selectedMovie.totalvotes - 1
                };
                setVotesCount(selectedMovie.totalvotes - 1);
                const voteToBeDeleted = {
                    id: voteID
                }
                await API.graphql({
                    query: deleteVotesMutation,
                    variables: { input: voteToBeDeleted },
                });
                await API.graphql({
                    query: updateMovieMutation,
                    variables: { input: movie },
                });
            }
            else {
                return;
            }
            setVoteAction(null);
            setActionInProgress(false);
        }
        updateVotes();
    }, [voteAction, voteID, props.id, props.user.username]), [voteAction, voteID, props.id, props.user.username]);

    const onClickHandler = useCallback((event) => {
        if (actionInProgress === true){
            // console.log('Already acting on it...hence exiting');
            return;
        }
        setActionInProgress(true);
        if (liked) {
            setLiked(false);
            setVoteAction('REDUCE');
        }
        else {
            setLiked(true);
            setVoteAction('ADD');
        }
        setClicked(true);
    }, [liked, actionInProgress]);

    const onAnimationEnd = useCallback(() => {
        setClicked(false);
    }, []);

    return (
        <React.Fragment>
            <button
                key={`likeb` + props.id}
                id={props.id}
                onClick={onClickHandler}
                onAnimationEnd={onAnimationEnd}
                className={cn("like-button-wrapper", {
                    liked,
                    clicked,
                })}
            >
                {liked && (
                    <div className="particles">
                        {particleList.map((_, index) => (
                            <div
                                className="particle-rotate"
                                key={props.id + index}
                                style={{
                                    transform: `rotate(${(360 / particleList.length) * index + 1
                                        }deg)`,
                                }}
                            >
                                <div className="particle-tick" />
                            </div>
                        ))}
                    </div>
                )}
                <div className="like-button">
                    <span>{votesCount} &nbsp;</span>
                    <Hand />
                    <span>Like</span>
                    <span className={cn("suffix", { liked })}>d</span>
                </div>
            </button>
        </React.Fragment>
    );
};

export default LikeButton;