/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMovie = /* GraphQL */ `
  query GetMovie($id: ID!) {
    getMovie(id: $id) {
      id
      title
      openingtext
      video
      conversionstatus
      converteduri
      totalvotes
      votes {
        items {
          id
          user
          createdAt
          updatedAt
          movieVotesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMovies = /* GraphQL */ `
  query ListMovies(
    $filter: ModelMovieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMovies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        openingtext
        video
        conversionstatus
        converteduri
        totalvotes
        votes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVotes = /* GraphQL */ `
  query GetVotes($id: ID!) {
    getVotes(id: $id) {
      id
      user
      createdAt
      updatedAt
      movieVotesId
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: ModelVotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        createdAt
        updatedAt
        movieVotesId
      }
      nextToken
    }
  }
`;
