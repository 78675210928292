import React, { useRef, useEffect, useCallback } from 'react';
import Hls from 'hls.js';

function VideoPlayer(props) {
    const videoRef = useRef('');

    const showVideoPlayer = useCallback(() => {
        var config = {
            xhrSetup: function (xhr, url) {
                xhr.withCredentials = true; // do send cookies
            }
        }
        
        const hls = new Hls(config);
        const url = props.url;
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            // console.log('video and hls.js are now bound together !');
        });
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            // console.log(
            //     'manifest loaded, found ' + data.levels.length + ' quality level'
            // );
        });
        hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log('fatal network error encountered, try to recover');
                        hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log('fatal media error encountered, try to recover');
                        hls.recoverMediaError();
                        break;
                    default:
                        // cannot recover
                        hls.destroy();
                        break;
                }
            }
        });
        // console.log(props.user.signInUserSession);
        // console.log(url.slice(0, url.indexOf("clippings.puneetsd.people.aws.dev/")+34)+"clippingauth"+props.user.signInUserSession.accessToken.jwtToken+"authend/"+url.slice(url.indexOf("clippings.puneetsd.people.aws.dev/")+34));
        // hls.loadSource(url.slice(0, url.indexOf("clippings.puneetsd.people.aws.dev/")+34)+"clippingauth"+props.user.signInUserSession.accessToken.jwtToken+"authend/"+url.slice(url.indexOf("clippings.puneetsd.people.aws.dev/")+34));
        hls.loadSource(url);
        hls.attachMedia(videoRef.current);
    }, [props.url, props.user.signInUserSession])

    // function playMovieHandler(event) {
    //     console.log(event);
    //     videoRef.current.play();
    // }

    useEffect(() => {
        showVideoPlayer();
    }, [showVideoPlayer])

    return (
        <React.Fragment>
            <video
                controls
                controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                width="400" height="300"
                ref={videoRef}
                autoPlay={false}>
            </video>
            {/* <button onClick={playMovieHandler}>Play Clipping</button> */}
        </React.Fragment>
    );
}

export default VideoPlayer;