import React from 'react';
import ReactDOM from 'react-dom/client';
// import { CookiesProvider } from "react-cookie";

import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);
Amplify.configure({
    Auth: {
        // (optional) - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // - Cookie domain (only required if cookieStorage is provided)
            domain: '.anyc.puneetsd.people.aws.dev',
            // (optional) - Cookie path
            path: '/',
            // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'lax' ,
            // (optional) - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },
    }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
