import React, { useState, useEffect, useCallback } from 'react';
// import { useCookies } from "react-cookie";
import MoviesList from './components/MoviesList';
import AddMovie from './components/AddMovie';
import './App.css';
import "@aws-amplify/ui-react/styles.css";
import { API, Storage } from "aws-amplify";
import {
  withAuthenticator,
  user, 
  signOut
  // Button,
  // Heading,
  // Image,
  // View,
  // Card
} from "@aws-amplify/ui-react";
import { listMovies, listVotes } from "./graphql/queries";
import {
  createMovie as createMovieMutation
} from "./graphql/mutations";


function App({ signOut, user }) {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [votes, setVotes] = useState([]);

  
  const getVotesForUser = useCallback(async () => {
    let filter = {
      user: {
        eq: user.username
      }
    };
    const apiData = await API.graphql({ query: listVotes, variables: { filter: filter } });
    const votesForUser = apiData.data.listVotes.items;
    setVotes(votesForUser);
  }, [user])

  const fetchMoviesHandler = useCallback(async () => {
    setIsLoading(true);
    await getVotesForUser();
    setError(null);
    try {
      let filter = {
        conversionstatus: {
          eq: 'COMPLETE'
        }
      };
      const apiData = await API.graphql({ query: listMovies, variables: { filter: filter } });
      const moviesFromAPI = apiData.data.listMovies.items;
      setMovies(moviesFromAPI);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchMoviesHandler();
  }, [fetchMoviesHandler, getVotesForUser]);

  const addMovieHandler = useCallback(async (movie, video) => {
    setIsLoading(true);
    const response = await API.graphql({
      query: createMovieMutation,
      variables: { input: movie },
    });
    if (!!movie.video) {
      let extension = ''
      if (response.data.createMovie.video.indexOf(".") > 0) {
        extension = response.data.createMovie.video.substring(response.data.createMovie.video.indexOf("."));
      }
      await Storage.put(response.data.createMovie.id + "/" + response.data.createMovie.id + extension, video);
    }
    getVotesForUser();
    fetchMoviesHandler();
  }, [fetchMoviesHandler]);

  let content = <p>Found no clippings.</p>;

  if (isLoading) {
    content = <p>Loading...</p>;
  }
  else if (movies.length === 1) {
    content = movies.length + ' Clipping';
  }
  else if (movies.length > 1) {
    content = movies.length + ' Clippings';
  }

  if (error) {
    content = <p>{error}</p>;
  }

  return (
    <React.Fragment>
      {/* <section>
        <AddMovie onAddMovie={addMovieHandler} />
      </section> */}
      {/* <section> */}
      <button onClick={signOut}>Sign out</button>
      {/* </section> */}
      <section>
        <div>Welcome {user.username}</div>
        <button onClick={fetchMoviesHandler}>Fetch Clippings</button>
        <div>{content}</div>
      </section>
      <MoviesList movies={movies} user={user} votes={votes}/>
    </React.Fragment>
  );
}

export default withAuthenticator(App);
